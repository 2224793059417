<template>
    <div class="mainc">
        <div style="height:200px"></div>
        <div class="fuwuapp">
            <div class="titileimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/fuwu.png')"></el-image>
            </div>
            <div class="firstimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/fuwu1.png')"></el-image>
            </div>
            <div class="twoimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/fuwu2.png')"></el-image>
            </div>
        </div>
        <div class="first">
            <div class="titileimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/yun.png')"></el-image>
            </div>
            <div class="firstimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/yun1.png')"></el-image>
            </div>
            <div class="firstdown" @click="zhiyunxiazai('1','android')">Android Download</div>
            <div class="twoimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/yun2.png')"></el-image>
            </div>
            <div class="twodown" @click="zhiyunxiazai('1','ios')">IOS Download</div>
            <div class="textcontent">
                <div class="firstcn">智云平台是一款高并发，低延时，高定制的机组在线监控平台</div>
                <div class="firsten">SmartGen cloud plus is high concurrent，lowdelay，high customization unit online monitoring platform</div>
            </div>
        </div>
        <div class="chanpinapp">
            <div class="titileimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/chanpin.png')"></el-image>
            </div>
            <div class="firstimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/chanpin1.png')"></el-image>
            </div>
            <div class="firstdown" @click="zhiyunxiazai('2','android')">Android Download</div>
            <div class="firstimage">
                <el-image style="width:100%" :src="require('@/assets/iphone/chanpin1.png')"></el-image>
            </div>
            <div class="firstdown" @click="zhiyunxiazai('2','ios')">IOS Download</div>
            <div class="textcontent">
                <div class="firstcn">众智产品APP，及时获取产品的详细信息</div>
                <div class="firsten">SmartGen product APP can obtain the detailed information of the product</div>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    beforeCreate() {
        document.querySelector('body').setAttribute('style', "background:#008AEE")
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    data(){
        return{
            type:''
        }
    },
    methods:{
        zhiyunxiazai(types,iphone) {
            if(types == 1){
                if(iphone == 'ios'){
                    window.open('https://apps.apple.com/cn/app/id1574149315')
                }else{
                    window.open('/data/androiddownload/SGCP.apk')
                }
            }else if(types == 2){
                if(iphone == 'ios'){
                    window.open('https://apps.apple.com/cn/app/id1584584753')
                }else{
                    window.open('/data/androiddownload/SmartGenProduct.apk')
                }
            }
            // if(iphone == 'ios'){
            //     window.webkit.messageHandlers.download.postMessage(types);
            // }else{
            //     android.download(types)
            // }
        }
    }
}
</script>
<style lang="scss" scoped>
    .mainc{
        background: url("../../assets/iphone/fenxiangbck.png");
        background-size: 100% 100%;
        height: 100%;
    }
    .first{
        width: 80%;
        margin: 0 auto;
        height: 650px;
        background: #fff;
        border-radius: 16px;
        
        
    }
    .titileimage{
        width: 50%;
        margin: 0 auto;
        padding-top: 20px;
    }
    .firstimage{
        width: 160px;
        height: 160px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .firstdown{
        width: 152px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        margin: 0 auto;
        background: #0052D9;
        border-radius: 4px;
        font-size: 15px;
        font-family: SourceHanSansCN-Normal;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .twoimage{
        width: 160px;
        height: 160px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .twodown{
        width: 152px;
        height: 44px;
        line-height: 44px;
        margin: 0 auto;
        text-align: center;
        background: #0052D9;
        border-radius: 4px;
        font-size: 15px;
        font-family: SourceHanSansCN-Normal;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .textcontent{
        padding-left: 12%;
        padding-right: 12%;
        color: #0052D9;
        text-align:justify;
        .firstcn{
            margin-top: 10px;
            font-size: 12px;
            font-family: SourceHanSansCN-Normal;
            font-weight: 400;
            cursor: pointer;
        }
        .firsten{
            margin-top: 10px;
            font-size: 11px;
            text-justify:inter-ideograph;
            cursor: pointer;
        }
    }
    .chanpinapp{
        width: 80%;
        height: 627px;
        background: #FFFFFF;
        border-radius: 16px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .fuwuapp{
        width: 80%;
        height: 480px;
        background: #FFFFFF;
        border-radius: 16px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>